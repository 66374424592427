<template>
  <div v-show="visible">
    <div v-show="visible" class="container content-band">
      <h2 v-show="title" class="band-title">{{ $t(title) }}</h2>
      <div :id="`flicking-wrapper-${index}`">
        <flicking
          ref="flicking"
          :options="{
            align: align,
            center: center,
            circular: circular,
            bound: bound,
            moveType: ['freeScroll'],
            deceleration: deceleration,
            defaultIndex: -1,
            disableOnInit: disableInputs
          }"
          :plugins="plugins"
          :viewportTag="'div'"
          :cameraTag="'div'"
          :key="flicking_dummy"
        >
          <div
            v-for="nugget in nuggets"
            :key="nugget.version_id"
            class="carousel-slide"
          >
            <div class="nugget-container">
              <nugget-card
                :nugget="nugget"
                :rotate_state="nugget.rotate_state"
                @rotated="nuggetRotated(nugget, $event)"
              ></nugget-card>
            </div>
          </div>
          <div slot="viewport" class="flicking-pagination"></div>
        </flicking>
        <span
          class="flicking-arrow flicking-arrow-prev is-outside"
          :class="arrowClass"
        ></span>
        <span
          class="flicking-arrow flicking-arrow-next is-outside"
          :class="arrowClass"
        ></span>
      </div>
    </div>
  </div>
</template>
<script>
import NuggetCard from "./NuggetCard";
import nugget_list_mixin from "@/mixins/nugget_list_mixin";
import { Flicking } from "@egjs/vue-flicking";
import { Arrow, Pagination } from "@egjs/flicking-plugins";
import "@egjs/vue-flicking/dist/flicking.css";
import "@egjs/flicking-plugins/dist/arrow.css";
import "@egjs/flicking-plugins/dist/pagination.css";

export default {
  name: "NuggetSlider",
  mixins: [nugget_list_mixin],
  components: { Flicking, NuggetCard },
  props: ["index", "limit", "center"],
  data() {
    var deceleration = 0.006;
    if (window.innerWidth < 992) deceleration = 0.003;
    return {
      visiblePanelsCount: 0,
      deceleration: deceleration,
      flicking_dummy: 0
    };
  },
  watch: {
    visiblePanelsCount() {
      this.init();
    }
  },
  methods: {
    spyEnd() {},
    init() {
      if (this.visiblePanelsCount) {
        var flicking = this.$refs.flicking;
        flicking.moveTo(0);
        this.flicking_dummy++;
      }
    }
  },
  mounted() {
    // Make the visiblePanelsCount react on ui changes
    this.watch_interval = setInterval(() => {
      var flicking = this.$refs.flicking;
      if (flicking?.getPanel(0) && flicking.$el) {
        // flicking.viewport.width is not responsive to display changes
        const width = flicking.$el.getBoundingClientRect().width;
        this.visiblePanelsCount = Math.floor(
          width / flicking.getPanel(0).sizeIncludingMargin
        );
      } else {
        this.visiblePanelsCount = 0;
      }
    }, 50);
  },
  destroyed: function() {
    clearTimeout(this.watch_interval);
  },
  computed: {
    // Is this slider displayed
    visible() {
      return this.nuggets.length >= this.limit;
    },
    // Is this slider is circular
    circular() {
      return false;
    },
    // Becase we cannot set circular and bound
    bound() {
      return this.circular ? undefined : true;
    },
    plugins() {
      // If there is enough nuggets in the category we add arrows and pagination
      if (this.visiblePanelsCount < this.nuggets.length) {
        return [
          new Arrow({
            moveCount: this.visiblePanelsCount - 1,
            parentEl: document.getElementById("flicking-wrapper-" + this.index)
          }),
          new Pagination({ type: "bullet" })
        ];
      }
      return [];
    },
    // Disable inputs
    disableInputs() {
      return this.visiblePanelsCount >= this.nuggets.length;
    },
    // Show or hide the slider arrows
    arrowClass() {
      return {
        hidden: this.disableInputs
      };
    },
    // Panels alignment in slider
    align() {
      return this.center ? { camera: "50%", panel: "0%" } : "prev";
    }
  }
};
</script>
<style scoped lang="scss">
@import "../assets/scss/nugget.scss";
@import "../assets/scss/nugget_slider.scss";
</style>
