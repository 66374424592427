<template>
  <AppTemplate :jumbotron-label1="$t('notFound.title')">
    <div class="main main-raised">
      <div class="not-found">
        <div class="error-code">404</div>
        <div class="error-message">
          <p>{{ $t("notFound.message") }}</p>
        </div>
      </div>
      <div class="buttons">
        <router-link to="/">
          <md-button class="md-primary md-block">
            {{ $t("notFound.backToHomepage") }}
          </md-button>
        </router-link>
        <a :href="getUrl('jsonLinksFile:feedback_mlearn')" target="_blank">
          <md-button class="md-secondary md-block">
            {{ $t("notFound.contactSupport") }}
          </md-button>
        </a>
      </div>
    </div>
  </AppTemplate>
</template>

<script>
import AppTemplate from "@/components/AppTemplate.vue";
import microlearning_mixin from "@/mixins/microlearning_mixin";

export default {
  name: "NotFound",
  mixins: [microlearning_mixin],
  components: {
    AppTemplate
  }
};
</script>

<style scoped>
.main {
  padding: 100px;
}

.not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-bottom: 75px;
  color: black;
}

.error-code {
  font-size: 13rem;
  letter-spacing: -35px;
  font-weight: bold;
  margin-right: 4rem;
}

.error-message {
  max-width: 400px;
}

p {
  font-size: 1rem;
  color: #555;
  margin-bottom: 2rem;
}

.buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

@media (max-width: 767px) {
  .main {
    padding-top: 100px;
    padding-bottom: 50px;
  }

  .not-found {
    flex-direction: column;
    padding-bottom: 50px;
  }

  .error-code {
    font-size: 8rem;
    letter-spacing: -22px;
    margin: 0;
  }

  .error-message {
    max-width: 90%;
    font-size: 1rem;
    margin-top: 70px;
  }

  .buttons {
    flex-direction: column;
    gap: 0;
    align-items: center;
  }
}
</style>
