<template>
  <md-toolbar
    id="toolbar"
    md-elevation="0"
    class="md-fixed"
    :class="{
      [`md-${type}`]: !navbarTransparent,
      [extraNavClasses]: navbarTransparent,
      'md-transparent': navbarTransparent
    }"
    :color-on-scroll="colorOnScroll"
  >
    <div
      class="md-toolbar-row md-collapse-lateral"
      v-click-outside="hideMobileMenu"
    >
      <div class="md-toolbar-section-start">
        <!-- Brand -->
        <div id="logo" class="md-toolbar-section-start">
          <router-link class="navbar-brand" :to="{ name: 'Home' }">
            <img
              src="@/assets/img/logo-navbar-white.png"
              :alt="$t('app.name')"
              class="nav-logo nav-logo-navbar-white"
            />
            <img
              src="@/assets/img/logo-navbar-color.png"
              :alt="$t('app.name')"
              class="nav-logo nav-logo-navbar-color"
            />
          </router-link>
        </div>
      </div>

      <div class="md-toolbar-section-end">
        <!-- Search -->
        <div class="md-autocomplete input-group no-border md-list-item">
          <md-field class="nav-search">
            <label>{{ $t("search") }}</label>
            <md-input
              id="nav-search-input"
              v-model="search_fulltext"
              @keyup.enter="doSearch"
              type="text"
            />
            <div class="clear-container">
              <md-button
                v-if="search_fulltext"
                class="md-button-link md-just-icon md-round md-simple clear-search"
                @click="search_fulltext = ''"
              >
                <md-icon v-if="search_fulltext">clear</md-icon>
              </md-button>
            </div>
          </md-field>
        </div>
        <md-button
          type="submit"
          class="search-button md-primary md-just-icon md-round md-button-link md-list-item menu-button"
          @click="doSearch"
        >
          <md-icon>search</md-icon>
        </md-button>

        <!-- Hamburger -->
        <div>
          <md-button
            class="hamburger md-just-icon md-simple md-toolbar-toggle"
            :class="{ toggled: this.NavbarStore.showNavbar }"
            @click="toggleMobileMenu()"
          >
            <span class="icon-bar" />
            <span class="icon-bar" />
            <span class="icon-bar" />
          </md-button>
          <div class="tooltip-wrapper mobile">
            <div
              v-if="!$store.getters.authenticated && hasAuthBasic"
              class="tooltip"
            >
              <div class="tooltip-top">{{ $t("more_login") }}</div>
            </div>
          </div>
        </div>
        <div class="md-collapse">
          <md-button
            class="menu-close md-simple md-close md-just-icon md-round modal-default-button"
            @click="toggleMobileMenu()"
          >
            <md-icon>clear</md-icon>
          </md-button>
          <div class="md-collapse-wrapper">
            <md-list>
              <!-- User menu -->
              <li class="profile-list md-list-item menu-button">
                <a
                  href="javascript:void(0)"
                  class="md-list-item-router md-list-item-container md-button-clean dropdown"
                >
                  <drop-down ref="dropdown" direction="down">
                    <md-button
                      slot="title"
                      class="
                        md-primary
                        md-just-icon
                        md-round
                        md-button-link
                        md-list-item
                      "
                      data-toggle="dropdown"
                    >
                      <md-icon>menu</md-icon>
                    </md-button>
                    <ul class="dropdown-menu dropdown-menu-right">
                      <li>
                        <a
                          href="javascript:;"
                          class="dropdown-item item-disabled"
                        >
                          <i class="material-icons">perm_identity</i>
                          {{ $t("menu.profile") }}
                        </a>
                      </li>
                      <li v-if="$store.getters.authenticated">
                        <router-link to="/profile" class="dropdown-item">
                          <i class="material-icons">account_circle</i>
                          {{ $t("menu.profile") }}
                        </router-link>
                        <router-link to="/my_nuggets" class="dropdown-item">
                          <i class="material-icons">account_circle</i>
                          {{ $t("menu.my_nuggets") }}
                        </router-link>
                      </li>
                      <li v-else>
                        <ChangeLanguage />
                      </li>
                      <li>
                        <a
                          href="javascript:;"
                          class="dropdown-item item-disabled"
                        >
                          <i class="material-icons">emoji_events</i>
                          {{ $t("menu.achievements") }}
                        </a>
                      </li>
                      <li>
                        <a
                          href="javascript:;"
                          class="dropdown-item item-disabled"
                        >
                          <i class="material-icons">trending_up</i>
                          {{ $t("menu.analytics") }}
                        </a>
                      </li>
                      <li>
                        <a
                          :href="documentationUrl"
                          target="_blank"
                          class="dropdown-item"
                          :class="{ 'item-disabled': !documentationUrl }"
                        >
                          <i class="material-icons">help_outline</i>
                          {{ $t("menu.documentation") }}
                        </a>
                      </li>
                      <li>
                        <a
                          :href="feedbackUrl"
                          target="_blank"
                          class="dropdown-item"
                          :class="{ 'item-disabled': !feedbackUrl }"
                        >
                          <i class="material-icons">comment</i>
                          {{ $t("menu.feedback") }}
                        </a>
                      </li>
                      <li>
                        <a
                          :href="aboutUrl"
                          target="_blank"
                          class="dropdown-item"
                          :class="{ 'item-disabled': !aboutUrl }"
                        >
                          <i class="material-icons">info</i>
                          {{ $t("menu.about") }}
                        </a>
                      </li>
                      <li>
                        <a
                          :href="changelogUrl"
                          target="_blank"
                          class="dropdown-item"
                          :class="{ 'item-disabled': !changelogUrl }"
                        >
                          <i class="material-icons">lightbulb</i>
                          {{ $t("menu.changelog") }}
                        </a>
                      </li>
                      <li v-if="$store.getters.authenticated">
                        <a
                          href="javascript:;"
                          class="dropdown-item"
                          @click="doLogout"
                        >
                          <i class="material-icons">logout</i>
                          {{ $t("auth.logout") }}
                        </a>
                      </li>
                    </ul>
                  </drop-down>
                </a>
              </li>
              <!-- Login -->
              <div v-if="!$store.getters.authenticated && hasAuthBasic">
                <md-button
                  v-on:click="showLoginModal"
                  class="login-button md-primary"
                  >{{ $t("auth.login") }}</md-button
                >
                <div class="tooltip-wrapper desktop">
                  <div class="tooltip">
                    <div class="tooltip-top">{{ $t("more_login") }}</div>
                  </div>
                </div>
              </div>
              <md-button
                v-if="signUp && !$store.getters.authenticated"
                :href="signUp"
                class="md-simple transparent"
                >{{ $t("auth.basic.sign_up") }}</md-button
              >
              <a
                v-if="!$store.getters.authenticated && hasAuthShibboleth"
                :href="config.authentication.shibboleth.discovery_service"
              >
                <md-button class="shibboleth-login-button md-primary">{{
                  $t("auth.login")
                }}</md-button>
              </a>
            </md-list>
          </div>
        </div>
      </div>
    </div>
  </md-toolbar>
</template>
<script>
import config_mixin from "@/mixins/config_mixin";
import jsonLinksFile from './../../links_config.json';

let resizeTimeout;
function resizeThrottler(actualResizeHandler) {
  // ignore resize events as long as an actualResizeHandler execution is in the queue
  if (!resizeTimeout) {
    resizeTimeout = setTimeout(() => {
      resizeTimeout = null;
      actualResizeHandler();

      // The actualResizeHandler will execute at a rate of 15fps
    }, 66);
  }
}
import search_mixin from "@/mixins/search_mixin";
import microlearning_mixin from "@/mixins/microlearning_mixin";
import ChangeLanguage from "@/components/ChangeLanguage";

export default {
  name: "AppNav",
  emits: ["login-modal"],
  mixins: [search_mixin, microlearning_mixin, config_mixin],
  components: { ChangeLanguage },
  props: {
    type: {
      type: String,
      default: "white"
    },
    colorOnScroll: {
      type: Number,
      default: 150
    },
    navbarTransparent: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      extraNavClasses: "", // For transparent navbar
      jsonLinksFile: jsonLinksFile // For all documentation links
    };
  },
  computed: {
    hasAuthBasic() {
      return this.config.authentication && this.config.authentication.basic;
    },
    hasAuthShibboleth() {
      return (
        this.config.authentication && this.config.authentication.shibboleth
      );
    },
    signUp() {
      try {
        return this.$store.getters.config.layout.auth.sign_up;
      } catch (e) {
        return "";
      }
    },
    documentationUrl() {
      return this.getMenuUrl("documentation_url");
    },
    feedbackUrl() {
      return this.getMenuUrl("feedback_url");
    },
    changelogUrl() {
      return this.getMenuUrl("changelog_url");
    },
    aboutUrl() {
      return this.getMenuUrl("about_url");
    }
  },
  mounted() {
    document.addEventListener("scroll", this.scrollListener);
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.scrollListener);
  },
  methods: {
    getMenuUrl(key) {
      try {
        let keyValue = this.$store.getters.config.layout.menu[key];

        if (keyValue.startsWith("jsonLinksFile")) return jsonLinksFile[ keyValue.replace(/^jsonLinksFile:/, '') ].[this.$i18n.locale];
        else return keyValue;
      }
      catch (e) {
        return "";
      }
    },
    handleScroll() {
      let scrollValue =
        document.body.scrollTop || document.documentElement.scrollTop;
      let navbarColor = document.getElementById("toolbar");
      this.currentScrollValue = scrollValue;
      if (this.colorOnScroll > 0 && scrollValue > this.colorOnScroll) {
        this.extraNavClasses = `md-${this.type}`;
        navbarColor.classList.remove("md-transparent");
      } else {
        if (this.extraNavClasses) {
          this.extraNavClasses = "";
          navbarColor.classList.add("md-transparent");
        }
      }
    },
    scrollListener() {
      resizeThrottler(this.handleScroll);
    },
    showMobileMenu() {
      this.NavbarStore.showNavbar = true;
    },
    hideMobileMenu() {
      this.NavbarStore.showNavbar = false;
    },
    toggleMobileMenu() {
      this.NavbarStore.showNavbar = !this.NavbarStore.showNavbar;
    },
    showLoginModal() {
      // Closing the mobile menu
      this.hideMobileMenu();
      this.$store.commit("login_modal", true);
    },
    // Logout actions
    doLogout() {
      this.$store.commit("logout");
      this.$router.push({ name: "Home" });
      this.$store.commit("info", this.$t("auth.logout_message"));
    }
  }
};
</script>
<style scoped lang="scss">
#toolbar {
  padding-right: 0 !important;
}
.nav-logo {
  max-height: 70px;
  position: relative;
  width: auto !important;
}
#toolbar.md-transparent {
  padding-top: 10px !important;
}
/* Changement de logo en fonction de l'état de la Navbar */
#toolbar.md-transparent .nav-logo-navbar-color,
#toolbar:not(.md-transparent) .nav-logo-navbar-white {
  display: none;
}
#toolbar.md-transparent #nav-search-input,
#toolbar.md-transparent .sign-up .md-button-content {
  -webkit-text-fill-color: white !important;
}
.clear-container {
  width: 40px;
  min-width: 40px;
}
// Clear search input button
.clear-search {
  transform: scale(0.6);
  z-index: 3;

  i {
    font-size: 25px !important;
    line-height: 30px !important;
  }
}

.menu-button {
  margin-right: 15px !important;
}
.menu-close {
  margin-right: -10px !important;
  display: none;
}
.login-button,
.shibboleth-login-button {
  width: 100%;
}

/* Tooltip */
.tooltip-wrapper {
  transform: scale(1, 1);
  position: relative;
  z-index: 1;
  margin-right: -50px;
}
.tooltip {
  position: fixed;
  left: -150px;
  color: #ffffff;
  display: block;
}
.tooltip-top {
  background: $brand-danger;
  border-radius: 4px;
  padding: 4px;
}
.tooltip-top::before {
  content: "";
  position: fixed;
  display: inline-block;
  width: 0px;
  left: 20px;
  top: 5px;
  border: 15px solid transparent;
  border-top: 0;
  border-bottom: 15px solid $brand-danger;
  transform: translate(-50%, calc(-100% - 5px));
}

.nav-open .md-collapse {
  padding: 25px;
  border-left: 1px solid #eee;
}
.nav-open .md-collapse .search-button {
  display: none;
}
.nav-open .md-collapse .md-list-item {
  margin-bottom: 30px;
}
.nav-open .md-collapse button[data-toggle="dropdown"] {
  display: none;
}
.nav-open .md-collapse a.dropdown {
  margin: 0px !important;
}
.nav-open .md-collapse ul.dropdown-menu {
  display: block !important;
  width: 180px;
  overflow: hidden;
}
.nav-open .md-collapse ul.dropdown-menu li {
  left: -35px;
}

.dropdown-item {
  &.item-disabled {
    pointer-events: none !important;
    color: #aaa !important;

    &:focus,
    &:hover {
      color: #aaa !important;
      cursor: default;
      text-decoration: none !important;
      background-color: transparent !important;
      background-image: none !important;
      box-shadow: none !important;
    }
  }
}
.item-disabled {
  display: none;
}

/* Make search button display above search field (useful on mobile) */
.search-button {
  z-index: 100;
}
/* Remove ugly webkit autocomplete style from Search input*/
#nav-search-input {
  background-clip: text !important;
  -webkit-background-clip: text !important;
}
/* prevents hamburger from falling behind search widget */
.md-toolbar-toggle {
  z-index: 1000;
}
/* We hide the close menu button and replace it by another inside the menu */
.hamburger.toggled {
  opacity: 0;
}

@media screen and (max-width: 768px) {
  .nav-search {
    width: 100px;
  }
}
@media screen and (max-width: 991px) {
  #toolbar.md-transparent .sign-up .md-button-content {
    -webkit-text-fill-color: black !important;
  }
  .menu-close {
    display: block;
  }
  .md-button.md-simple.sign-up {
    color: black !important;
  }
}
</style>
