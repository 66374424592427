<template>
  <div class="container view-header text-center">
    <!-- Structure logo -->
    <div class="structure">
      <div
        v-for="(structure, index) in nugget.managing_structuresData"
        :key="index"
      >
        <router-link
          :to="{
            name: 'Structure',
            params: structureLinkParams(structure),
            hash: '#structure_top'
          }"
        >
          <img
            class="structure-logo"
            :src="structure.structure_thumbnail_url"
          />
        </router-link>
      </div>
    </div>
    <!-- Authors -->
    <h4 class="authors">
      <FullName
        v-for="author in nugget.authorsData"
        :key="author.email"
        :person="author"
        :link="true"
      >
      </FullName>
    </h4>

    <!-- Nugget name -->
    <h1 class="nugget-name">{{ nugget.name }}</h1>

    <!-- Navigation -->
    <div class="tabs">
      <div v-for="tab in tabs" :key="tab.route">
        <router-link
          :to="{
            name: tab.route,
            params: nuggetLinkParams(nugget)
          }"
        >
          <md-button
            class="md-round desktop-tab"
            :class="$route.name == tab.route ? 'md-primary' : 'md-simple'"
          >
            <i class="material-icons nugget-nav-tab-icon">{{ tab.icon }}</i>
            {{ tab.text }}
          </md-button>
          <span
            class="mobile-tab"
            :class="$route.name == tab.route ? 'active' : ''"
          >
            <div class="nugget-nav-tab">
              <i class="material-icons nugget-nav-tab-icon">{{ tab.icon }}</i>
              {{ tab.text }}
            </div>
          </span>
        </router-link>
      </div>
      <NuggetMultilanguageSelect :nugget="nugget"> </NuggetMultilanguageSelect>
    </div>
  </div>
</template>
<script>
import microlearning_mixin from "@/mixins/microlearning_mixin";
import FullName from "@/components/FullName";
import NuggetMultilanguageSelect from "@/components/NuggetMultilanguageSelect";

export default {
  name: "NuggetNav",
  props: ["nugget"],
  mixins: [microlearning_mixin],
  components: { FullName, NuggetMultilanguageSelect },
  computed: {
    tabs() {
      const tabs = [
        {
          route: "NuggetDetail",
          icon: "info",
          text: this.$t("about")
        },
        {
          route: "NuggetView",
          icon: "play_arrow",
          text: this.$t("learn")
        }
      ];
      if (this.nugget?.open_education?.is_open_education)
        tabs.push({
          route: "NuggetShare",
          icon: "share",
          text: this.$t("share")
        });
      return tabs;
    }
  }
};
</script>
<style scoped lang="scss">
.tabs {
  display: flex;
  flex-direction: row !important;
  justify-content: center;
}

.structure {
  display: flex;
  justify-content: center;
}
.structure > div {
  margin-left: 15px;
  margin-right: 15px;
}
img.structure-logo {
  max-height: 60px;
  width: auto;
}
.nugget-name {
  font-size: 2.5em;
  padding-bottom: 25px;
}
.view-header .authors a {
  display: block;
}
.mobile-tab {
  padding-left: 100px;
}
.mobile-tab.active {
  color: white;
  text-shadow: 1px 1px 3px $brand-primary;
}
.desktop-tab.md-button.md-simple,
.desktop-tab.md-button.md-simple i {
  color: #ffffff !important;
}
@media screen and (max-width: 440px) {
  .tabs {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 600px) {
  .desktop-tab {
    display: none;
  }
}
@media screen and (min-width: 601px) {
  .mobile-tab {
    display: none;
  }
}
</style>
