var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('rotating-card',{staticStyle:{"max-width":"500px"},attrs:{"card-class":"md-card-background","layer-background":"","front-card-image":_vm.cardRotating.cardRotatingBg,"manual-rotate-state":_vm.localManualRotateState,"manual-rotate":""}},[_c('template',{slot:"frontRotateCardContent"},[_c('div',{staticClass:"front-container",on:{"click":_vm.flip_card}},[_c('div',{staticClass:"nugget-card-name"},[_c('router-link',{staticClass:"card-title-link",attrs:{"to":{
            name: 'NuggetView',
            params: this.nuggetLinkParams(_vm.nugget),
            hash: '#nugget_top'
          }},nativeOn:{"dragstart":function($event){$event.preventDefault();}}},[_c('h4',{staticClass:"card-title",attrs:{"title":_vm.nugget.name}},[_vm._v(" "+_vm._s(_vm.truncateTitle(_vm.nugget.name))+" ")])])],1),_c('div',{staticClass:"nugget-card-author"},[(_vm.nugget.authorsData && _vm.nugget.authorsData.length > 0)?_c('p',{staticClass:"card-title"},[_c('FullName',{attrs:{"person":_vm.nugget.authorsData[0],"link":false}}),(_vm.nugget.authorsData.length > 1)?_c('span',[_vm._v(" et al.")]):_vm._e()],1):_vm._e()]),_c('div',{staticClass:"nugget-card-logo"},_vm._l((_vm.nugget.structure_thumbnail_url),function(structure_url,index){return _c('img',{key:index,attrs:{"alt":"Structure logo","src":structure_url},on:{"dragstart":function($event){$event.preventDefault();}}})}),0)]),_c('div',{staticClass:"position"},[_c('i',{staticClass:"material-icons green md-48"},[_vm._v(_vm._s(_vm.icon))])])]),_c('template',{slot:"backRotateCardContent"},[_c('div',{staticClass:"back-container",on:{"click":_vm.flip_card}},[_c('span',[_c('router-link',{staticClass:"nugget-detail-link",attrs:{"to":{
            name: 'NuggetDetail',
            params: this.nuggetLinkParams(_vm.nugget),
            hash: '#nugget_top'
          }},nativeOn:{"dragstart":function($event){$event.preventDefault();}}},[_c('i',{staticClass:"material-icons card-icon"},[_vm._v("info")])]),_c('router-link',{staticClass:"nugget-view-link",attrs:{"to":{
            name: 'NuggetView',
            params: this.nuggetLinkParams(_vm.nugget),
            hash: '#nugget_top'
          }},nativeOn:{"dragstart":function($event){$event.preventDefault();}}},[_c('i',{staticClass:"material-icons card-icon"},[_vm._v("play_arrow")])])],1),_c('div',{staticClass:"card-description"},[_c('div',{ref:"clamp",staticClass:"description",domProps:{"innerHTML":_vm._s(_vm.resume)}}),_c('p',{staticClass:"duration text-center"},[_vm._v(_vm._s(_vm.nugget.duration)+" MIN")]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.show_rating),expression:"show_rating"}],staticClass:"rating-wrapper text-center middle"},[_c('Rated',{staticClass:"small",attrs:{"rate":_vm.rate.score,"user":_vm.rate.votes}})],1)])])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }