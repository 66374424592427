import Vue from "vue";
import VueI18n from "vue-i18n";
Vue.use(VueI18n);

// Create i18n Object
var i18n = new VueI18n({
  locale:
    localStorage.getItem("user_language") ||
    process.env.VUE_APP_I18N_LOCALE ||
    "en",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: {}
});

// Loads local messages from files in locales folder and try to override them (with data comming from the server for instance)
function loadLocaleMessages(overrides) {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  // Read each file in the locales folder
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      var messages = locales(key);
      // Try to override messages
      if (overrides && overrides[locale]) {
        Object.assign(messages, overrides[locale]);
      }
      i18n.setLocaleMessage(locale, messages);
      document.documentElement.lang = i18n.locale;
    }
  });
}
loadLocaleMessages();

export { loadLocaleMessages };
export default i18n;
