<template>
  <div class="background page">
    <!-- Loader -->
    <loading v-if="!loadedIframe"></loading>

    <div
      class="text-center gallery row"
      :class="loadedIframe ? '' : 'hidden'"
      id="learn"
    >
      <!-- LTI Form -->
      <form
        :action="lti_data.url"
        ref="form"
        method="POST"
        encType="application/x-www-form-urlencoded"
        target="lti_frame"
      >
        <input
          v-for="(value, key) in lti_data.params"
          :key="key"
          type="hidden"
          :name="key"
          :value="value"
        />
      </form>
      <!-- Post LTI Frame -->
      <iframe
        v-if="frame_src"
        :src="frame_src"
        ref="lti_frame"
        id="lti_frame"
        name="lti_frame"
        class="lti_frame"
      ></iframe>
    </div>

    <!-- Nugget Completing Button -->
    <NuggetFooter v-if="displayFooter" :nugget="nugget"></NuggetFooter>
  </div>
</template>
<script>
import iframeResize from "iframe-resizer";
import NuggetFooter from "@/components/NuggetFooter.vue";
import Loading from "@/components/Loading";
import microlearning_mixin from "@/mixins/microlearning_mixin";
import axios from "axios";

export default {
  name: "NuggetView",
  props: ["nugget"],
  components: {
    NuggetFooter,
    Loading
  },
  mixins: [microlearning_mixin],
  data() {
    return {
      lti_data: {},
      frame_src: undefined,
      loadedIframe: false,
      displayFooter: false
    };
  },
  watch: {
    nugget() {
      this.load();
    }
  },
  mounted() {
    let init = false;
    let timeOut = null;
    // We spy on the messages sent by the iframe while it's loading
    window.addEventListener("message", e => {
      if (e.data && typeof e.data === "string") {
        // First we wait for the "init" message from the iframe
        if (!init && e.data.includes("init")) {
          init = true;
          // Resources are loading
        }
        if (init && e.data.includes("lti_frame")) {
          clearTimeout(timeOut);
          timeOut = setTimeout(() => {
            // When nothing has loaded for more than 600 ms -> ready
            this.loadedIframe = true;
            if (document.getElementById("lti_frame") != null) {
              if (document.getElementById("lti_frame").offsetHeight > 170)
                this.displayFooter = true;
            }
          }, 600);
        }
      }
    });
    if (this.nugget.nugget_id) {
      this.load();
    }
  },
  methods: {
    async load() {
      this.frame_src = false;
      try {
        this.lti_data = await this.get_lti_data(this.nugget.nugget_id);
      } catch (e) {
        this.accessDenied();
        return;
      }

      var bodyFormData = new FormData();
      for (const [key, value] of Object.entries(this.lti_data.params))
        bodyFormData.append(key, value);
      axios({
        method: "post",
        url: this.lti_data.url,
        data: bodyFormData
      })
        .then(response => {
          this.frame_src = response.headers.location;
          this.$nextTick(() => {
            iframeResize.iframeResizer(
              { checkOrigin: false, heightCalculationMethod: "lowestElement" },
              this.$refs.lti_frame
            );
          });

          this.$store.commit("version_id", this.nugget.version_id);
          // View xapi statement
          this.updateNuggetStatus("experienced", this.nugget);
        })
        .catch(() => {
          this.$store.commit("error", this.$t("unexpected_error"));
        });
    }
  }
};
</script>
<style scoped lang="scss">
.lti_frame {
  border: none;
  width: 100%;
  height: auto;
  padding-bottom: 20px;
}
.hidden {
  opacity: 0;
  height: 20px;
  overflow: hidden; /* Hiding the scollbar */
}
</style>
