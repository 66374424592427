<template>
  <div class="background">
    <div class="section">
      <div
        v-if="
          nugget &&
            nugget.open_education &&
            nugget.open_education.is_open_education
        "
        class="container-fluid"
      >
        <div>
          <h2>
            Intellectual properties
          </h2>
          <p>
            <LicenceImage :nugget="nugget" />
            <br />
            {{ $t("attribution.licence") }}
          </p>
          <p>
            <b>
              {{ $t("attribution.copy") }}
            </b>
          </p>
          <div class="code-section">
            <code>{{ get_attribution }} </code>
            <clipboard
              class="copy-code primary-background"
              :content="get_attribution"
            ></clipboard>
          </div>
        </div>
        <!-- Direct link -->
        <h2>
          Direct link
        </h2>
        <div class="code-section">
          <code>{{ nugget.open_education.link }} </code>
          <clipboard
            class="copy-code primary-background"
            :content="nugget.open_education.link"
          ></clipboard>
        </div>
        <!-- Embed -->
        <h2>
          Embed in web page
        </h2>
        <div class="code-section">
          <code v-text="embed_code"></code>
          <clipboard
            class="copy-code primary-background"
            :content="embed_code"
          ></clipboard>
        </div>
        <!-- LTI -->
        <h2>LTI integration</h2>
        <div class="lti-integration">
          <h3>Tool URL</h3>
          <div class="code-section">
            <code>{{ nugget.open_education.lti.url }}</code>
            &nbsp;<clipboard
              class="md-primary copy-code primary-background"
              :content="nugget.open_education.lti.url"
            ></clipboard>
          </div>
          <h3>Key</h3>
          <div class="code-section">
            <code>{{ nugget.open_education.lti.key }}</code
            >&nbsp;<clipboard
              class="copy-code primary-background"
              :content="nugget.open_education.lti.key"
            ></clipboard>
          </div>
          <h3>Secret</h3>
          <div class="code-section">
            <code>{{ nugget.open_education.lti.secret }}</code
            >&nbsp;<clipboard
              class="copy-code primary-background"
              :content="nugget.open_education.lti.secret"
            ></clipboard>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Clipboard from "@/components/Clipboard";
import LicenceImage from "./LicenceImage.vue";
import microlearning_mixin from "@/mixins/microlearning_mixin";

export default {
  name: "NuggetDetail",
  props: ["nugget"],
  components: { Clipboard, LicenceImage },
  mixins: [microlearning_mixin],
  computed: {
    embed_code() {
      /* eslint-disable no-useless-escape */
      return `<script src="https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/4.3.2/iframeResizer.min.js"><\/script>
<iframe class="nugget" src="${this.nugget.open_education.link}"  style="border:0; width:800px"></iframe>
<script>iFrameResize({ heightCalculationMethod: "lowestElement" }, '.nugget')<\/script>`;
    },
    get_attribution() {
      var author = this.nugget.authorsData[0];
      var producer = "";
      var coproducer = "";

      if (this.nugget.managing_structuresData[1]) {
        producer = this.nugget.managing_structuresData[0].acronym;
        coproducer =
          " in coproduction with " +
          this.nugget.managing_structuresData[1].acronym;
      } else {
        producer = this.nugget.managing_structuresData[0].acronym;
      }

      return (
        "Nugget by " +
        author.firstname +
        " " +
        author.lastname +
        " produced by " +
        producer +
        coproducer +
        " on " +
        window.location.host
      );
    }
  },
  methods: {
    fallbackCopyTextToClipboard() {
      const text = this.get_attribution;
      var textArea = document.createElement("textarea");
      textArea.value = text;

      // Avoid scrolling to bottom
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        document.execCommand("copy");
        this.notifyUser();
      } catch (err) {
        console.error("Fallback: Oops, unable to copy", err);
      }

      document.body.removeChild(textArea);
    },
    notifyUser() {
      this.$store.commit("info", this.$t("content_copied"));
    },
    copy() {
      const text = this.get_attribution;
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(text);
        return;
      } else
        navigator.clipboard
          .writeText(text)
          .then(this.notifyUser, function(err) {
            console.error("Async: Could not copy text: ", err);
          });
    }
  }
};
</script>
<style scoped lang="scss">
td.keys {
  width: 100px;
  text-align: right;
  padding-right: 15px;
}
p {
  font-size: 16px;
}
code {
  width: 100%;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}
.section {
  padding-top: 0px;
}
.code-section {
  background-color: #efefef;
  max-width: 100%;
  position: relative;
  display: flex;
}
.code-section code {
  padding: 20px;
}
.code-section .copy-code {
  padding: 0px 10px 0px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
