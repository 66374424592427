var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.visible),expression:"visible"}]},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.visible),expression:"visible"}],staticClass:"container content-band"},[_c('h2',{directives:[{name:"show",rawName:"v-show",value:(_vm.title),expression:"title"}],staticClass:"band-title"},[_vm._v(_vm._s(_vm.$t(_vm.title)))]),(_vm.nugget_list.length > 0)?_c('div',[_c('div',{attrs:{"id":("flicking-wrapper-" + _vm.index)}},[_c('flicking',{key:_vm.flicking_dummy,ref:"flicking",attrs:{"options":{
            align: _vm.align,
            center: _vm.center,
            circular: _vm.circular,
            bound: _vm.bound,
            moveType: ['freeScroll'],
            deceleration: _vm.deceleration,
            defaultIndex: -1,
            disableOnInit: _vm.disableInputs
          },"plugins":_vm.plugins,"viewportTag":'div',"cameraTag":'div'}},[_vm._l((_vm.nugget_list),function(nugget){return _c('div',{key:nugget.version_id,staticClass:"carousel-slide"},[(nugget[0] != null)?_c('div',{staticClass:"nugget-container"},[_c('nugget-card',{attrs:{"nugget":nugget[0],"rotate_state":nugget.rotate_state},on:{"rotated":function($event){return _vm.nuggetRotated(nugget, $event)}}})],1):_vm._e()])}),_c('div',{staticClass:"flicking-pagination",attrs:{"slot":"viewport"},slot:"viewport"})],2),_c('span',{staticClass:"flicking-arrow flicking-arrow-prev is-outside",class:_vm.arrowClass}),_c('span',{staticClass:"flicking-arrow flicking-arrow-next is-outside",class:_vm.arrowClass})],1),(!_vm.loaded)?_c('div',[_c('loading')],1):_vm._e()]):_c('div',{staticClass:"error"},[_c('h4',[_vm._v(_vm._s(_vm.$t("no_nuggets")))])])])])}
var staticRenderFns = []

export { render, staticRenderFns }