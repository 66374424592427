<template>
  <ul class="related-domains-list">
    <li class="related-domains-list-element">
      <label
        class="badge badge-pill badge-margin related-domains-label"
        :class="bucketClass(bucket)"
        :title="bucket.caption"
        @click="bucketClick(bucket.query_value)"
      >
        {{ truncateMobileMode(bucket.caption, 25) }}
      </label>
      <span
        style="font-size: 12px;"
        v-if="hasChildren"
        :class="{
          'tree-view-caret': hasChildren,
          'tree-view-caret-down': showChildren
        }"
        @click="toggleChildren()"
      ></span>
    </li>

    <li
      class="related-domains-list-element related-domains-child"
      v-show="showChildren"
    >
      <ul
        v-for="children in bucket.children"
        :key="children.key"
        class="related-domains-list"
        style="margin: 0 0 0 20px;"
      >
        <li class="related-domains-list-element">
          <RelatedDomain
            :bucket="children"
            :truncateMobileMode="truncateMobileMode"
            :bucketClass="bucketClass"
            @bucket-click="bucketClick"
          ></RelatedDomain>
        </li>
      </ul>
    </li>
  </ul>
</template>
<script>
export default {
  name: "RelatedDomain",
  props: {
    bucket: {},
    truncateMobileMode: {
      type: Function,
      required: true
    },
    bucketClass: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      showChildren: this.childSelected(this.bucket.children)
    };
  },
  computed: {
    hasChildren() {
      return Object.keys(this.bucket.children).length > 0;
    }
  },
  methods: {
    childSelected(bucket) {
      if (Array.isArray(bucket)) return bucket.some(this.childSelected);
      else if (typeof bucket === "object")
        return Object.values(bucket).some(this.childSelected);
      else return bucket === true;
    },
    toggleChildren() {
      this.showChildren = !this.showChildren;
    },
    bucketClick(bucket_key) {
      this.$emit("bucket-click", bucket_key);
    }
  }
};
</script>
<style scoped lang="scss">
@import "../assets/scss/facets.scss";
</style>
