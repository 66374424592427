<template>
  <div :class="{ 'nav-open': NavbarStore.showNavbar }">
    <router-view @resize="refresh++" :key="refresh" />
  </div>
</template>
<script>
import microlearning_mixin from "@/mixins/microlearning_mixin";
export default {
  name: "App",
  mixins: [microlearning_mixin],
  data() {
    return {
      // Variable that controls when the router view needs to be refreshed (window resize, authentication change...)
      refresh: 0
    };
  },
  async created() {
    // When user leaves site
    //  window.addEventListener("beforeunload", this.beforeUnload);
    // Load config from server
    this.$store.commit("config", await this.getConfig());
    if (this.$route.query.token) {
      this.authenticateToken(this.$route.query.token);
    }
    //Get viewed/completed nuggets
    this.refreshUserStats();
    // Check if saved connexion is still valid now and every hour
    this.checkCurrentTokenValidity();
    window.setInterval(() => this.checkCurrentTokenValidity(), 1000 * 60 * 60);
  },
  computed: {
    authenticated() {
      return this.$store.getters.authenticated;
    }
  },
  watch: {
    authenticated() {
      // We reload the router view on login / logout
      this.refresh++;
    },
    $route() {
      // Reset document title (which can be changed by nugget pages)
      if (!this.$route.name?.startsWith("Nugget"))
        document.title = this.$t("app.name");
    }
  }
};
</script>
