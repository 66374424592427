<template>
  <AppTemplate
    :jumbotron-size="50"
    :jumbotron-background-url="structure.structure_banner_url"
  >
    <div class="main main-raised" id="structure_top">
      <div class="container view-header text-center">
        <div>
          <img
            alt="Structure logo"
            class="structure-logo resize-mobile"
            :src="`${structure.structure_thumbnail_url}?width=200`"
          />
        </div>
      </div>
      <div class="background">
        <div class="section">
          <div class="container-fluid">
            <div v-if="structure.description">
              <h2>{{ $t("about_us") }}</h2>
              <div class="description" v-html="structure.description"></div>
            </div>
            <h4 class="link" v-if="structure.website">
              <a :href="structure.website" target="_blank">{{ website }}</a>
            </h4>
            <div class="nugget-list">
              <nugget-list
                :title="$t('our_adns')"
                :params="{ producers: structure.structure_id }"
                nuggetClass="nugget-container"
              ></nugget-list>
            </div>
          </div>
        </div>
      </div>
    </div>
  </AppTemplate>
</template>
<script>
import AppTemplate from "@/components/AppTemplate.vue";
import NuggetList from "@/components/NuggetList.vue";
import microlearning_mixin from "@/mixins/microlearning_mixin";
import config from "@/config";

export default {
  name: "Structure",
  mixins: [microlearning_mixin],
  components: {
    AppTemplate,
    NuggetList
  },
  data() {
    return {
      structure: {}
    };
  },
  async created() {
    this.load();
    this.onLogin(this.load);
  },
  computed: {
    website() {
      return this.getWebsite(this.structure);
    }
  },
  methods: {
    async load() {
      try {
        this.structure = await this.get_structure(
          this.$route.params.structure_id
        );
      } catch (e) {
        this.accessDenied();
      }
      document.title = config.title + " - " + this.structure["name"];
    }
  }
};
</script>
<style scoped lang="scss">
.section {
  padding-top: 20px;
}

.background {
  padding-top: 15px;
}

.container-fluid {
  max-width: 1024px;
  margin: auto;
}

.resize-mobile {
  margin: 100px;
}

@media screen and (max-width: 768px) {
  .resize-mobile {
    margin: 55px;
  }
}
</style>
