import VueCookies from "vue-cookies";

export const COOKIE_DURATION = "6m"; // 6 mois

// Create cookie
export function setCookie(name, value, duration = COOKIE_DURATION) {
  const cookieValue = typeof value === "object" ? JSON.stringify(value) : value;
  VueCookies.set(name, cookieValue, duration);
}

// Get cookie Value
export function getCookie(name) {
  const cookieValue = VueCookies.get(name);
  try {
    return JSON.parse(cookieValue);
  } catch (e) {
    return cookieValue;
  }
}

// Delete cookie
export function removeCookie(name) {
  VueCookies.remove(name);
}

// Cookie exist
export function cookieExists(name) {
  return VueCookies.isKey(name);
}

// Cookie Pop Up showed
export function cookiePopUpShowed(value) {
  setCookie("microlearning_cookie_gestion", value);
}
