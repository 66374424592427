<template>
  <AppTemplate
    :jumbotron-background-url="`${nugget.nugget_thumbnail_url}?width=2048`"
    jumbotron-label1=""
    jumbotron-label2=""
    :jumbotron-size="50"
  >
    <div class="main main-raised" id="nugget_top">
      <nugget-nav :nugget="nugget"></nugget-nav>
      <slot></slot>
    </div>
  </AppTemplate>
</template>
<script>
import AppTemplate from "./AppTemplate";
import NuggetNav from "./NuggetNav";
import microlearning_mixin from "@/mixins/microlearning_mixin";
import config from "@/config";

export default {
  name: "TemplateNugget",
  components: { AppTemplate, NuggetNav },
  props: ["nugget_id"],
  data() {
    return {
      nugget: {}
    };
  },
  mixins: [microlearning_mixin],
  watch: {
    async nugget_id() {
      this.load();
    }
  },
  created() {
    this.load();
    this.onLogin(this.load);
  },
  methods: {
    async load() {
      try {
        this.nugget = await this.getNuggetWithOpenEducationInformation(
          this.nugget_id
        );
      } catch (e) {
        this.accessDenied();
      }
      try {
        const promises = [];
        promises.push(this.loadNuggetAuthors(this.nugget));
        promises.push(this.loadNuggetReviewers(this.nugget));
        promises.push(this.loadNuggetManagingStructures(this.nugget));
        promises.push(this.loadNuggetDomains(this.nugget));
        promises.push(this.loadNuggetAccessLicence(this.nugget));
        await Promise.all(promises);
      } catch (e) {
        console.log(e);
      }
      this.nugget = Object.assign({}, this.nugget);

      this.$emit("nugget", this.nugget);

      var authors = [];
      this.nugget["authorsData"].forEach(author => {
        authors.push([author["firstname"], author["lastname"]].join(" "));
      });
      document.title =
        config.title + " - " + this.nugget["name"] + " - " + authors.join(", ");
    }
  }
};
</script>
