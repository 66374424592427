<template>
  <div class="section nugget-footer" onunload="unload()">
    <div id="app">
      <div class="container text-center">
        <div class="done-button">
          <md-button @click="finish" class="md-round md-success md-wd md-lg">
            {{ $t("nugget.finish") }}
          </md-button>
        </div>
        <div v-show="isModalVisible">
          <nugget-completion-modal @close="closeModal" :nugget="this.nugget" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import microlearning_mixin from "@/mixins/microlearning_mixin";
import NuggetCompletionModal from "./NuggetCompletionModal";

export default {
  name: "NuggetFooter",
  props: ["nugget"],
  mixins: [microlearning_mixin],
  components: {
    NuggetCompletionModal
  },
  data: function() {
    return {
      isModalVisible: false,
      initial: window.location.href,
      loading: false,
      completed_message_sent: false,
      storage: "",
      completed_data: null
    };
  },
  watch: {
    // Hide modal if nugget changes
    nugget() {
      this.isModalVisible = false;
    }
  },
  mounted: function() {
    // To change the shared link url if the user comes from the detail page
    let url = window.location.href.split("/");
    if (url.at(-1) !== "view") {
      url.pop();
      this.initial = url.join("/") + "/view";
    }
  },
  methods: {
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    async finish() {
      if (!this.completed_message_sent) {
        this.completed_message_sent = true;
        this.updateNuggetStatus("completed", this.nugget);
      }
      this.showModal();
    }
  }
};
</script>
<style scoped>
.done-button {
  margin: auto;
}
.done-button button {
  font-weight: bold;
  white-space: normal;
}
.nugget-footer {
  padding-top: 0px !important;
}
</style>
