<template>
  <div>
    <h2 v-if="title && nuggets.length > 0">{{ $t(title) }}</h2>
    <div class="md-layout md-alignment-top-center">
      <div
        v-for="nugget in nuggets"
        :key="nugget.version_id"
        class="md-layout-item"
        :class="nuggetClass"
      >
        <nugget-card
          :nugget="nugget"
          :rotate_state="nugget.rotate_state"
          @rotated="nuggetRotated(nugget, $event)"
        ></nugget-card>
      </div>
    </div>

    <loading v-if="loading"></loading>

    <div ref="end"></div>
  </div>
</template>
<script>
import NuggetCard from "./NuggetCard";
import Loading from "./Loading";
import nugget_list_mixin from "@/mixins/nugget_list_mixin";
import utils from "@/utils";
export default {
  name: "NuggetList",
  props: ["nuggetClass"],
  mixins: [nugget_list_mixin],
  components: { NuggetCard, Loading },
  methods: {
    spyEnd() {
      setInterval(
        function() {
          if (
            !this.loading &&
            this.$refs.end &&
            utils.elementInViewport(this.$refs.end) &&
            (this.pages === undefined || this.page < this.pages)
          )
            this.load();
        }.bind(this),
        40
      );
    }
  }
};
</script>
<style>
div.md-layout {
  margin-left: 0px;
  margin-right: 0px;
}
</style>
