import microlearning_mixin from "@/mixins/microlearning_mixin";
export default {
  mixins: [microlearning_mixin],
  data() {
    return {
      partners: [],
      pages: undefined,
      loading: false,
      page: 0
    };
  },
  async created() {
    if (!this.structures || this.structures.length == 0) {
      this.spyEndPartners();

      // If a list of structure is given as parameters for display
    } else {
      this.partners = this.structures;
    }
  },
  methods: {
    async loadPartners() {
      // Loads partners
      if (this.loading) {
        this.reload = true;
        return;
      }
      this.loading = true;

      var producers = [];
      this.searchNuggets().then(async response => {
        response.aggregations.producers.buckets.forEach(item => {
          producers.push(item.key);
        });
        var promise = this.searchPartners()
          .then(async response => {
            response.items.forEach(item => {
              if (producers.includes(item.structure_id))
                this.partners.push(item);
            });
            this.pages = response.pages;
            this.page++;
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
            this.pages = 0;
          });
        return promise;
      });
    }
  }
};
