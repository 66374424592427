<template>
  <div class="background">
    <div class="section">
      <div class="container-fluid">
        <div class="md-layout">
          <div class="md-layout-item md-size-65 md-medium-size-100">
            <!-- Description -->
            <div v-if="isShown(nugget.resume)">
              <h2>{{ $t("description") }}</h2>
              <div class="p-position" v-html="nugget.resume"></div>
            </div>
            <!-- About author -->
            <div v-if="isShown(nugget.authorsData)">
              <h2>
                {{
                  $tc(
                    "about_author",
                    nugget.authors != undefined && nugget.authors.length > 0
                  )
                }}
              </h2>
              <p class="p-position">
                <author-info
                  v-for="author in nugget.authorsData"
                  :key="author.email"
                  :author="author"
                ></author-info>
              </p>
            </div>
            <div v-if="isShown(nugget.reviewersData)">
              <h2>
                {{ $t("nugget.reviewers") }}
              </h2>
              <ul style="list-style: none">
                <li
                  v-for="reviewer in nugget.reviewersData"
                  :key="reviewer.email"
                >
                  <FullName :person="reviewer" :link="true"></FullName>
                </li>
              </ul>
            </div>
          </div>
          <div class="md-layout-item md-size-35 md-medium-size-100">
            <div v-if="config.use_stats">
              <h2>
                {{ $t("average_rating") }}
              </h2>
              <div class="rating-wrapper">
                <Rated
                  :nugget="nugget"
                  :rate="rate.score"
                  :user="rate.votes"
                  class="big"
                ></Rated>
              </div>
            </div>
            <!-- In brief -->
            <div v-if="inBriefShown">
              <h2>{{ $t("in_brief") }}</h2>
              <div>
                <ul style="list-style: none">
                  <li v-if="isShown(nugget.duration)">
                    <i class="material-icons icon-position">schedule</i>
                    {{ $t("nugget.duration") }}:
                    <strong id="formatage-duration">
                      {{ nugget.duration }} {{ $t(`minutes`) }}
                    </strong>
                  </li>
                  <li v-if="isShown(nugget.language)">
                    <i class="material-icons icon-position">language</i>
                    {{ $t("nugget.language") }}:
                    <strong>{{ $t(`language.${nugget.language}`) }}</strong>
                  </li>
                  <li v-if="isShown(nugget.level)">
                    <i class="material-icons icon-position">height</i>
                    {{ $t("nugget.level") }}:
                    <strong>{{ $t(`nugget.levels.${nugget.level}`) }}</strong>
                  </li>
                  <li v-if="isShown(nugget.publication_date)">
                    <i class="material-icons icon-position">calendar_month</i>
                    {{ $t("nugget.publication_date") }}:
                    <strong>{{ nugget.publication_date | formatDate }}</strong>
                  </li>
                  <li v-if="isShown(nugget.domainsData)">
                    <i class="material-icons icon-position">home</i>
                    {{ $t("nugget.domains") }}<br />
                    <span v-for="item in nugget.domainsData" :key="item.id">
                      <router-link
                        :to="{
                          name: 'Search',
                          query: { related_domains: item.id }
                        }"
                      >
                        <span class="badge badge-pill badge-primary">{{
                          item.label
                        }}</span>
                      </router-link>
                      <br
                    /></span>
                  </li>
                  <li v-if="isShown(nugget.tags)">
                    <i class="material-icons icon-position">sell</i>
                    {{ $t("nugget.tags") }}<br />
                    <span v-for="item in nugget.tags" :key="item">
                      <router-link
                        :to="{ name: 'Search', query: { tags: item } }"
                      >
                        <span class="badge badge-pill badge-primary">{{
                          item
                        }}</span>
                      </router-link>
                      <br
                    /></span>
                  </li>
                  <li v-if="isShown(nugget.access_licence)">
                    <i class="material-icons icon-position">anchor</i>
                    {{ $t("nugget.licence") }}<br />
                    <LicenceImage :nugget="nugget" />
                    <!--
                    <span
                      v-for="right in nugget.access_licence.rights"
                      :key="right"
                      class="badge badge-pill badge-primary"
                    >
                      <span>{{ right }}</span>
                    </span>
                  --></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div>
          <!-- Prerequisites -->
          <div v-if="isShown(nugget.prerequisites)">
            <h2>{{ $t("nugget.prerequisites") }}</h2>
            <ul class="about-list ul-position">
              <li v-for="item in nugget.prerequisites" :key="item">
                <p>{{ item }}</p>
              </li>
            </ul>
          </div>
          <!-- Learning outcomes -->
          <div v-if="isShown(nugget.learning_outcomes)">
            <h2>{{ $t("nugget.learning_outcomes") }}</h2>
            <ul class="about-list ul-position">
              <li v-for="item in nugget.learning_outcomes" :key="item">
                <p>{{ item }}</p>
              </li>
            </ul>
          </div>
          <!-- References -->
          <div v-if="isShown(nugget.references)">
            <h2>{{ $t("nugget.references") }}</h2>
            <ul class="about-list ul-position">
              <li v-for="item in nugget.references" :key="item">
                <p>{{ item }}</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Rated from "@/components/Rated.vue";
import AuthorInfo from "@/components/AuthorInfo.vue";
import FullName from "@/components/FullName";
import microlearning_mixin from "@/mixins/microlearning_mixin";
import LicenceImage from "./LicenceImage.vue";

export default {
  name: "NuggetDetail",
  props: ["nugget"],
  mixins: [microlearning_mixin],
  components: { AuthorInfo, FullName, Rated, LicenceImage },
  data() {
    return {
      rate: 0
    };
  },
  watch: {
    nugget: async function() {
      this.load();
    }
  },
  created() {
    this.load();
  },
  methods: {
    async load() {
      if (this.nugget?.nugget_id != null) {
        this.rate = await this.getNuggetAverageScore(this.nugget.nugget_id);
      }
    },
    isShown(val) {
      if (val == undefined) return false;
      if (val == "") return false;
      if (Array.isArray(val) && val.length == 0) return false;
      if (typeof val === "object") {
        for (const inner_val of Object.values(val)) {
          if (this.isShown(inner_val)) return true;
        }
        return false;
      }
      return true;
    }
  },
  computed: {
    inBriefShown() {
      return (
        this.isShown(this.nugget.duration) ||
        this.isShown(this.nugget.level) ||
        this.isShown(this.nugget.tags) ||
        this.isShown(this.nugget.language)
      );
    }
  }
};
</script>
<style scoped lang="scss">
h2 {
  padding-left: 30px;
}
.rating-wrapper {
  margin-left: 32px;
}
.icon-position {
  top: 5px;
  position: relative;
}
.ul-position {
  margin-left: 27px;
}
.ul-position li p {
  font-size: 14pt;
}
.p-position {
  margin-left: 28px;
  font-size: 14pt;
  text-align: justify;
  text-justify: inter-word;
}

@media screen and (max-width: 991px) {
  .section {
    padding: 30px 0;
  }
  h2 {
    font-size: 24px !important;
  }
  p {
    font-size: 20px !important;
  }
}
</style>
