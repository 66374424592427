<template>
  <div class="modal modal-bg" :class="{ show: $store.getters.login_modal }">
    <modal
      id="login_modal"
      v-if="$store.getters.login_modal"
      modal-with-card
      type="modal-login"
      card-classes="md-card-login"
      @keydown.esc.native="hideModal"
      @close="hideModal"
    >
      <template slot="header">
        <md-card-header>
          <md-button
            class="md-simple md-white md-close md-just-icon md-round modal-default-button"
            @click="hideModal"
          >
            <md-icon>clear</md-icon>
          </md-button>
          <h4 class="card-title">
            {{ $t("auth.login") }}
          </h4>
        </md-card-header>
      </template>

      <template slot="body">
        <md-field slot="inputs" class="md-form-group">
          <md-icon>face</md-icon>
          <label>{{ $t("user.username") }}</label>
          <md-input
            ref="username"
            v-model="username"
            @keydown.enter.native="doLogin"
          />
        </md-field>
        <md-field slot="inputs" class="md-form-group">
          <md-icon>lock_outline</md-icon>
          <label>{{ $t("user.password") }}</label>
          <md-input
            v-model="password"
            type="password"
            @keydown.enter.native="doLogin"
          />
        </md-field>
        <a
          v-if="forgotPasswd"
          :href="forgotPasswd"
          class="md-simple forgot_passwd"
          >{{ $t("auth.forgot_passwd") }}</a
        >
      </template>

      <template slot="footer">
        <div class="login_footer">
          <md-button
            href="javascript:void(0)"
            class=" md-primary"
            @click="doLogin"
          >
            {{ $t("auth.login") }}
          </md-button>
          <loading v-if="loading"></loading>
          <a :href="getUrl('jsonLinksFile:feedback_mlearn')" target="_blank">
            {{ $t("contact_support") }}
          </a>
        </div>
      </template>
    </modal>
  </div>
</template>
<script>
import microlearning_mixin from "@/mixins/microlearning_mixin";
import Modal from "@/plugins/material-kit/components/Modal";
import Loading from "./Loading";

export default {
  name: "Login",
  components: { Modal, Loading },
  mixins: [microlearning_mixin],
  data() {
    return {
      username: "",
      password: "",
      loading: false
    };
  },
  methods: {
    doLogin() {
      this.loading = true;
      this.authenticate(this.username, this.password).then(user => {
        this.loading = false;
        if (user) {
          this.refreshUserStats();
          this.hideModal();
        }
      });
    },
    hideModal() {
      this.$store.commit("login_modal", false);
    }
  },
  watch: {
    login_modal: function(loginModal) {
      // Toggle CSS class
      if (loginModal) {
        document.body.classList.add("modal-open");
        // Focus username input
        setTimeout(() => {
          if (this.$refs.username) this.$refs.username.$el.focus();
        }, 100);
        // If we are on a sign up page we login from the home page
        if (
          this.$route.name == "SignUp" ||
          this.$route.name == "Shibboleth" ||
          this.$route.name == "ResetPassword" ||
          this.$route.name == "ChangePassword"
        ) {
          this.$router.push({ name: "Home" });
        }
      } else document.body.classList.remove("modal-open");
    }
  },
  computed: {
    forgotPasswd() {
      var config = this.$store.getters.config;
      try {
        return config.layout.auth.forgot_passwd;
      } catch (e) {
        return "";
      }
    },
    login_modal() {
      return this.$store.getters.login_modal;
    }
  }
};
</script>
<style scoped lang="scss">
.login_footer {
  display: flex;
  flex-direction: column;
}
.forgot_passwd {
  margin-left: 30px;
}
.modal {
  z-index: 10;
}
.modal-backdrop {
  z-index: 5 !important;
}
// Fix for the Bootstrap modal glitch
body {
  padding-right: 0 !important;
  background: white;
}
.modal-open {
  overflow-y: scroll;
  position: fixed;
  width: 100%;
}
.modal-label {
  margin-bottom: 10px;
}
.modal-checkbox {
  vertical-align: middle;
  position: relative;
  bottom: 1px;
}
.modal-label span:hover,
.modal-checkbox:hover {
  cursor: pointer;
}
</style>
