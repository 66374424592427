<template>
  <AppTemplate :jumbotron-size="50">
    <div class="main main-raised" id="author_top">
      <div class="section">
        <div class="container author-view text-center">
          <div class="md-layout">
            <div class="md-layout-item md-size-50 md-small-size-100 mx-auto">
              <div class="profile">
                <div v-if="author.has_thumbnail" class="avatar">
                  <img
                    :src="author.thumbnail_url"
                    alt="Circle Image"
                    class="img-raised rounded-circle img-fluid"
                  />
                </div>
                <div class="name">
                  <h3 class="title">
                    <FullName :person="author" :link="false"></FullName>
                  </h3>
                  <h6 v-if="author.department">{{ author.department }}</h6>
                  <h4 class="link" v-if="author.website">
                    <AuthorLink :authorWebsite="author.website"></AuthorLink>
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div class="md-layout">
            <div
              v-if="author.bio"
              class="md-layout-item md-size-70 mx-auto description text-justify"
              v-html="author.bio"
            ></div>
          </div>
          <div class="member-of" v-if="structures.length">
            <h2>{{ $t("member_of") }}</h2>
            <div class="partners-slider">
              <partners-list
                :structures="structures"
                partnersClass="md-layout-item partners-container"
              ></partners-list>
            </div>
          </div>

          <div class="nugget-list text-center">
            <nugget-list
              title="authors_nuggets"
              :params="{ authors: author.email }"
              nuggetClass="nugget-container"
            ></nugget-list>
          </div>
        </div>
      </div>
    </div>
  </AppTemplate>
</template>
<script>
import AppTemplate from "@/components/AppTemplate.vue";
import PartnersList from "@/components/PartnersList";
import NuggetList from "@/components/NuggetList.vue";
import AuthorLink from "@/components/AuthorLink";
import FullName from "@/components/FullName.vue";
import microlearning_mixin from "@/mixins/microlearning_mixin";
import config from "@/config";

export default {
  name: "Author",
  mixins: [microlearning_mixin],
  components: {
    AppTemplate,
    NuggetList,
    AuthorLink,
    FullName,
    PartnersList
  },
  data() {
    return {
      structures: [],
      author: {}
    };
  },
  watch: {
    author() {
      this.loadStructures();
    }
  },
  async created() {
    this.load();
    this.onLogin(this.load);
  },
  computed: {
    website() {
      return this.getWebsite(this.author);
    }
  },
  methods: {
    async load() {
      try {
        this.author = await this.get_person_cached(
          this.$route.params.author_id
        );
      } catch (e) {
        this.accessDenied();
      }
      document.title =
        config.title +
        " - " +
        this.author.firstname +
        " " +
        this.author.lastname;
    },
    async loadStructures() {
      this.structures = [];
      if (this.author && this.author.author_of_structures) {
        for (var i = 0; i < this.author.author_of_structures.length; i++) {
          let struct = this.author.author_of_structures[i];
          // Check duplicates
          if (
            this.author.author_of_structures.indexOf(struct) ==
            this.author.author_of_structures.lastIndexOf(struct)
          ) {
            this.structures.push(await this.get_structure_cached(struct));
          }
        }
      }
    }
  }
};
</script>
<style scoped lang="scss">
.author {
  .main-raised {
    background-color: white;
    margin-top: -450px;

    @media screen and (max-width: 768px) {
      margin-top: -150px;
    }
  }
  .section {
    padding: 30px;
  }
  .avatar img {
    max-width: 150px;
  }
  .title {
    cursor: inherit;
  }
}
</style>
