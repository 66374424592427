<template>
  <AppTemplate
    jumbotron-label1="app.name"
    jumbotron-label2="auth.sign_up.title"
    footer-disclaimer="true"
  >
    <div class="main main-raised main-narrow">
      <div class="box">
        <h2>{{ $t("auth.change_password.title") }}</h2>
        <div class="md-layout-item">
          <md-field>
            <label for="email">{{ $t("user.password") }}</label>
            <md-input
              ref="password"
              type="password"
              v-model="password"
              id="password"
              name="password"
              @keydown.enter.native="doUpdateAccount"
            ></md-input>
          </md-field>

          <md-field>
            <label for="email">{{ $t("user.password_confirmation") }}</label>
            <md-input
              type="password"
              v-model="password_confirmation"
              id="password_confirmation"
              name="password_confirmation"
              @keydown.enter.native="doUpdateAccount"
            ></md-input>
          </md-field>

          <md-button
            href="javascript:void(0)"
            class="md-success"
            @click="doUpdateAccount"
          >
            {{ $t("auth.change_password.button") }}
          </md-button>
          <loading v-if="loading"></loading>
        </div>
      </div>
    </div>
  </AppTemplate>
</template>
<script>
import AppTemplate from "@/components/AppTemplate.vue";
import config_mixin from "@/mixins/config_mixin";
import microlearning_mixin from "@/mixins/microlearning_mixin";
import Loading from "@/components/Loading";
import config from "@/config";

export default {
  name: "ChangePassword",
  mixins: [config_mixin, microlearning_mixin],
  components: {
    AppTemplate,
    Loading
  },
  data() {
    return {
      password: "",
      password_confirmation: "",
      loading: false
    };
  },
  created() {
    document.title = config.title + " - Change Password";
  },
  mounted() {
    this.focus_asap(this.$refs.password.$el);
  },
  methods: {
    async doUpdateAccount() {
      this.loading = true;
      if (this.password == "")
        this.$store.commit("error", this.$t("user.error_password_empty"));
      else if (this.password != this.password_confirmation)
        this.$store.commit("error", this.$t("user.error_passwords_dont_match"));
      else if (await this.updateAccount({ password: this.password }))
        this.$router.push("/");
      this.loading = false;
    }
  },
  computed: {}
};
</script>
<style soped lang="scss">
.box {
  padding: 25px;
}
</style>
