<template>
  <a
    href="javascript:void(0)"
    class="language-dropdown dropdown-item"
    ref="outside"
  >
    <drop-down ref="language-dropdown" direction="down">
      <span slot="title">
        <i class="material-icons">language</i>
        {{ $t("setting.language") }}
      </span>
      <ul class="dropdown-menu dropdown-menu-right">
        <li v-if="$i18n.locale != 'en'">
          <a class="dropdown-item" @click="selectedLanguage = 'en'">
            English
          </a>
        </li>
        <li v-if="$i18n.locale != 'fr'">
          <a class="dropdown-item" @click="selectedLanguage = 'fr'">
            Français
            {{ selected_language }}
          </a>
        </li>
      </ul>
    </drop-down>
  </a>
</template>

<script>
import microlearning_mixin from "@/mixins/microlearning_mixin";

export default {
  name: "ChangeLanguage",
  mixins: [microlearning_mixin],
  watch: {
    selectedLanguage: function(newVal) {
      //call the changeLanguage function only if the language selected is different
      if (newVal) this.changeLanguage();
    }
  },
  data() {
    return {
      selected_language: null
    };
  },
  methods: {
    changeLanguage() {
      this.$i18n.locale = this.selected_language;
      this.selected_language = null;
      // Close the menu by clicking outside
      document.body.click();
    }
  }
};
</script>
<style scoped lang="scss">
button {
  background: transparent;
  border: none !important;
}
@media (max-width: 991px) {
  .md-collapse-lateral
    .md-collapse
    .dropdown.open
    .dropdown-menu.dropdown-menu-right {
    overflow: hidden;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .md-collapse-lateral
    .md-collapse
    .dropdown.open
    .dropdown-menu.dropdown-menu-right:hover,
  .md-collapse-lateral
    .md-collapse
    .dropdown.open
    .dropdown-menu.dropdown-menu-right:focused {
    background-color: white;
  }
}
.md-primary + .dropdown-menu li a.language-dropdown:focus,
.md-primary + .dropdown-menu li a.language-dropdown:active {
  background-color: white !important;
  color: black !important;
  box-shadow: none;
}
</style>
