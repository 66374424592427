var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container view-header text-center"},[_c('div',{staticClass:"structure"},_vm._l((_vm.nugget.managing_structuresData),function(structure,index){return _c('div',{key:index},[_c('router-link',{attrs:{"to":{
          name: 'Structure',
          params: _vm.structureLinkParams(structure),
          hash: '#structure_top'
        }}},[_c('img',{staticClass:"structure-logo",attrs:{"src":structure.structure_thumbnail_url}})])],1)}),0),_c('h4',{staticClass:"authors"},_vm._l((_vm.nugget.authorsData),function(author){return _c('FullName',{key:author.email,attrs:{"person":author,"link":true}})}),1),_c('h1',{staticClass:"nugget-name"},[_vm._v(_vm._s(_vm.nugget.name))]),_c('div',{staticClass:"tabs"},[_vm._l((_vm.tabs),function(tab){return _c('div',{key:tab.route},[_c('router-link',{attrs:{"to":{
          name: tab.route,
          params: _vm.nuggetLinkParams(_vm.nugget)
        }}},[_c('md-button',{staticClass:"md-round desktop-tab",class:_vm.$route.name == tab.route ? 'md-primary' : 'md-simple'},[_c('i',{staticClass:"material-icons nugget-nav-tab-icon"},[_vm._v(_vm._s(tab.icon))]),_vm._v(" "+_vm._s(tab.text)+" ")]),_c('span',{staticClass:"mobile-tab",class:_vm.$route.name == tab.route ? 'active' : ''},[_c('div',{staticClass:"nugget-nav-tab"},[_c('i',{staticClass:"material-icons nugget-nav-tab-icon"},[_vm._v(_vm._s(tab.icon))]),_vm._v(" "+_vm._s(tab.text)+" ")])])],1)],1)}),_c('NuggetMultilanguageSelect',{attrs:{"nugget":_vm.nugget}})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }