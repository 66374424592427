<template>
  <div class="container text-center rating-container">
    <h3>{{ $t("nugget.rating.title") }}</h3>
    <p class="rating saved">
      <span
        v-for="i in max"
        v-bind:key="i"
        @click="rate(max + 1 - i)"
        class="star"
        :class="{ checked: saved_rating === max + 1 - i }"
      >
        <font-awesome-icon :icon="['fas', 'star']" />
      </span>
    </p>
    <p class="description">{{ $t("nugget.rating.description") }}</p>
  </div>
</template>
<script>
import microlearning_mixin from "@/mixins/microlearning_mixin";

// Font Awesome icons
import { library } from "@fortawesome/fontawesome-svg-core";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faStar);

/**
 * Component to rate the Nugget using stars on a 1 -> 5 scale
 */
// Maximum and minimum score
const MinScore = 1;
const MaxScore = 5;

export default {
  name: "Rating",
  mixins: [microlearning_mixin],
  components: {
    FontAwesomeIcon
  },
  data: function() {
    return {
      max: MaxScore,
      rating_sent: false,
      saved_rating: null
    };
  },
  watch: {
    $route() {
      this.sendRating();
    }
  },
  beforeDestroy() {
    this.sendRating();
  },
  methods: {
    rate(score) {
      this.saved_rating = score;
    },
    sendRating() {
      if (this.saved_rating != null && !this.rating_sent) {
        let body = {
          // Score
          raw: this.saved_rating,
          min: MinScore,
          max: MaxScore
        };
        this.postXapiStatement("rated", this.$store.getters.version_id, body);
        this.rating_sent = true;
      }
    }
  }
};
</script>
<style scoped lang="scss">
.rating-container {
  background-color: #f7f7f7;
  width: 50%;
  border-radius: 10px;
}
.rating {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
}
.star {
  color: lightgrey;
  font-size: 2em;
  padding: 1px;
}
.star:hover {
  animation: 2s linear infinite selected;
}
.star:hover,
.star:hover ~ .star {
  transform: scale(1.2);
  padding: 2px;
  color: orange;
}
.star.checked,
.star.checked ~ span {
  transform: scale(1.2);
  padding: 2px;
  color: orange;
}
.description {
  margin-top: 0px !important;
}

@keyframes rotate {
  0% {
    transform: rotate(0turn);
  }
  50% {
    transform: rotate(0.5turn);
  }
  100% {
    transform: rotate(1turn);
  }
}

@keyframes selected {
  0% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}
</style>
