<script>
import jsonLinksFile from './../../links_config.json';
import { cookiePopUpShowed } from './../cookieUtils';

export default {
  props: {
    show: Boolean,
    config: []
  },
  data: function() {
    return {
      jsonLinksFile: jsonLinksFile // For all documentation links
    };
  },
  computed: {
    cguUrl() {
      return this.getUrl("cgu_url");
    },
    dataUrl() {
      return this.getUrl("data_url");
    },
    cookieUrl() {
      return this.getUrl("cookie_url");
    }
  },
  methods: {
    saveCookieSettings() {},
    cookieForm(e) {
      if (e.target.name != null) {
        if (e.target.name != "") {
          cookiePopUpShowed(true);
          this.$parent.cookie_doc_create();
        }
      }
    },
    getUrl(key) {
      try {
        let keyValue = this.$store.getters.config.layout.url[key];

        if (keyValue.startsWith("jsonLinksFile")) return jsonLinksFile[ keyValue.replace(/^jsonLinksFile:/, '') ].[this.$i18n.locale];
        else return keyValue;
      }
      catch (e) {
        return "";
      }
    }
  },
  watch: {
    show: function() {
      cookiePopUpShowed(true);
    }
  }
};
</script>
<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask cookie-modal">
      <div class="modal-wrapper">
        <form
          v-on:submit.prevent="saveCookieSettings()"
          v-on:click="cookieForm($event)"
          class="modal-container"
        >
          <div class="md-card md-theme-default md-card-login">
            <div class="modal-header">
              <div class="md-card-header md-card-header-green">
                <button
                  type="button"
                  @click="$emit('close')"
                  class="md-button md-simple md-white md-close modal-default-button md-theme-default"
                >
                  <div class="md-button-content modal-close">
                    <i class="material-icons">close</i
                    >{{ $t("cookie.modal.close") }}
                  </div>
                </button>
                <h4 class="card-title">{{ $t("cookie.modal.title") }}</h4>
              </div>
            </div>
            <div class="modal-body">
              <div class="intro">{{ $t("cookie.modal.p-1") }}</div>
              <div class="intro">
                {{ $t("cookie.modal.p-2") }}
                <a class="cookie-link" target="_blank" :href="cookieUrl">{{
                  $t("cookie.Cookie")
                }}</a
                >.
              </div>
              <ul>
                <li class="content">
                  <div class="cookie-separator"></div>
                  <div class="cookie-title title">
                    {{ $t("cookie.modal.mandatory") }}
                  </div>
                  <div class="cookie-element">
                    <div class="cookie-col-1">
                      {{ $t("cookie.modal.mandatory-text") }}
                    </div>
                    <div class="cookie-col-2">
                      <button
                        class="cookie-btn"
                        style="background: lightgreen;"
                      >
                        <i class="material-icons">check</i>
                        {{ $t("cookie.modal.accepted") }}
                      </button>
                      <button
                        class="cookie-btn"
                        style="background: transparent;"
                      >
                        <i class="material-icons">check</i>
                        {{ $t("cookie.modal.refuse") }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="modal-footer">
              <a class="cookie-link" target="_blank" :href="cguUrl">{{
                $t("cookie.CGU")
              }}</a>
              -
              <a class="cookie-link" target="_blank" :href="dataUrl">{{
                $t("cookie.DATA")
              }}</a>
            </div>
          </div>
        </form>
      </div>
    </div>
  </Transition>
</template>
<style scoped lang="scss">
@import "../assets/scss/cookie.scss";

.modal-container {
  max-width: 750px;
}

.cookie-col-1,
.cookie-col-2 {
  float: left;
  padding: 0 20px 0 20px;
}
.cookie-col-1 {
  width: 65%;
  text-align: left;
}
.cookie-element {
  margin: 10px 20px 10px 20px;
}
.cookie-element:after {
  content: "";
  display: table;
  clear: both;
}
.cookie-separator {
  width: 50%;
  border-top: 1px solid $brand-primary;
  margin: 20px auto 20px auto;
}
.cookie-title {
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  margin: 10px 0 20px 0;
}
.cookie-description {
  text-align: left;
  padding: 10px;
}
.cookie-name {
  font-size: 16px;
  font-weight: bold;
}
.cookie-col-2 {
  width: 35%;
  text-align: right;
}
.cookie-col-2 > button {
  margin-right: 5px;
}
</style>
