<template>
  <div>
    <a :href="licence_info.link" target="_blank"
      ><img :src="licence_info.image" :alt="licence_info.alt" width="200"
    /></a>
  </div>
</template>

<script>
import microlearning_mixin from "@/mixins/microlearning_mixin";

export default {
  name: "LicenceImage",
  props: ["nugget"],
  mixins: [microlearning_mixin],
  data() {
    return {
      licence_info: ""
    };
  },
  watch: {
    nugget: async function() {
      this.load();
    }
  },
  created() {
    this.load();
  },
  methods: {
    async load() {
      if (this.nugget?.nugget_id != null) {
        this.licence_info = await this.getLicenceInfo();
      }
    }
  }
};
</script>
